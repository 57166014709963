<template>
  <s-drawer
    class="attention-drawer"
    :visible="visible"
    :append-to-body="true"
    :immediately-render="true"
    :show-close="true"
    :close-font-size="32"
    :close-color="'#fff'"
    :type="'multi'"
    @update:visible="$emit('close')"
  >
    <!-- 标题 S -->
    <template
      slot="top"
    > 
      <div class="top">
        <p class="tips">
          {{ tips }}
        </p>
        <section 
          v-if="showProducts.length"
          class="products-box"
        >
          <div 
            v-for="(item, i) in showProducts"
            :key="i"
            class="product"
          >
            <img
              class=""
              :src="item.goods_img"
            />
            <template v-if="i != 9">
              <div 
                class="discount-price"
                :class="{
                  'discount-black-price': !(item.retail_price && item.retail_price.amountWithSymbol),
                }"
              >
                {{ getSaleAmountWithSymbol(item) }}
              </div>
              <div
                v-if="item.retail_price && item.retail_price.amountWithSymbol"
                class="origin-price"
              >
                {{ getOriginAmountWithSymbol(item) }}
              </div>
            </template>
            <template v-else>
              <div 
                class="more" 
                @click="handlePick"
              >
                {{ language.SHEIN_KEY_PWA_22740 }}
              </div>
            </template>
          </div>
        </section>
      </div>
    </template>
    <div class="bottom">
      <s-button
        block
        :type="['H80PX', 'primary']"
        width="100%"
        @click="handlePick"
      >
        {{ confirmText }}
      </s-button>
      <p 
        class="give-up"
        @click="$emit('giveUp')"
      >
        {{ giveUpText }}
      </p>
    </div>
  </s-drawer>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'AttentionDrawer',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    tips: {
      type: String,
      default: '',
    },
    confirmText: {
      type: String,
      default: '',
    },
    giveUpText: {
      type: String,
      default: '',
    },
    canPickProducts: {
      type: Array,
      default: () => ([]) 
    },
  },
  computed: {
    ...mapState(['language']),
    showProducts() {
      return (this.canPickProducts || []).slice(0, 10)
    },
  },
  methods: {
    handlePick() {
      this.$emit('pick')
    },
    getSaleAmountWithSymbol(item) { // 售价带符号
      return item.sale_price?.amountWithSymbol
    },
    getOriginAmountWithSymbol(item) { // 原价带符号
      return item.retail_price?.amountWithSymbol
    },
    getOriginAmount(item) {
      return item.retail_price?.amount
    }
  }
}
</script>

<style lang="less" scoped>
.attention-drawer {
  /deep/ .S-drawer__container {
    border-radius: 14px 14px 0 0;
  }
  /deep/ .S-drawer__header {
    border-bottom: none;
  }
}
.top {
  background-size: cover;
  background-position: top;
  padding: 0 10/37.5rem;
  background-repeat: no-repeat;
  background-image: url('/pwa_dist/images/add-on/attention_bg-e7cc7cdb2a.png');
  .tips {
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;
    padding-top: 28/37.5rem;
    color: #fff;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
.products-box {
  max-width: 100%;
  margin-top: 16/37.5rem;
  padding: 4/37.5rem;
  background: #fff;
  border: 1px solid #FFE8E1;
  display: inline-flex;
  overflow-x: auto;
  .product {
    width: 74/37.5rem;
    text-align: center;
    flex-shrink: 0;
    position: relative;
    &:not(:last-child) {
      margin-right: 4/37.5rem;
    }
    
    img {
      width: 100%;
      height: 98/37.5rem;
    }
    .discount-price {
      margin-top: 4/37.5rem;
      color: #FA6338;
      font-weight: bold;
      line-height: 14px;
    }
    .discount-black-price {
      color: #000;
    }
    .origin-price {
      margin-top: 4/37.5rem;
      color: rgba(0, 0, 0, 0.30);
      line-height: 12px;
      text-decoration: line-through;
      .font-dpr(20px);
    }
    .more {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      height: 98/37.5rem;
      line-height: 98/37.5rem;
      background-color: rgba(0, 0, 0, 0.40);
      color: #fff;
      font-family: SF UI Text;
      .font-dpr(20px);
    }
  }
}
.bottom {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 24/37.5rem 12/37.5rem 16/37.5rem;
  .give-up {
    margin-top: 12/37.5rem;
    display: inline-block;
    font-size: 14px;
    color: #2D68A8;
  }
}
</style>
