<template>
  <span 
    ref="text" 
    class="over-text" 
    :style="{ '-webkit-line-clamp': clampLine }"
  >
    <slot></slot>
  </span>
</template>

<script>
export default {
  name: 'OverText',

  props: {
    clampLine: {
      type: Number,
      default: 1
    }
  },
  mounted () {
    this.checkOverflow(this.$refs.text)
  },
  methods: {
    // 校验文本是否溢出
    checkOverflow (el) {
      const curOverflow = el.style.overflow

      if (!curOverflow || curOverflow === 'visible') { el.style.overflow = 'hidden' }

      const isOverflowing =
        el.clientWidth < el.scrollWidth || el.clientHeight < el.scrollHeight

      el.style.overflow = curOverflow

      this.$emit('overed', isOverflowing)
    }
  }
}
</script>
<style lang="less" scoped>
.over-text {
  word-break: break-all;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
</style>
