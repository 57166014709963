<template>
  <Icon
    aria-hidden="true"
    name="sui_icon_close_18px"
    :size="size"
    :color="color"
    @click="handleClose"
  />
</template>

<script>
import { Icon } from '@shein-aidc/icon-vue2'
import { EventClose } from 'public/src/pages/common/add-on/utils/event.js'

export default {
  components: {
    Icon,
  },
  props: {
    size: {
      type: String,
      default: '12px',
    },
    color: {
      type: String,
      default: '#666',
    },
    closeInside: {
      type: Boolean,
      default: true,
    }
  },
  methods: {
    handleClose() {
      if (this.closeInside) {
        EventClose.notify()
        return
      }
      this.$emit('close')
    }
  },
}
</script>
