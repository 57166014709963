<template>
  <div class="gift-imgs">
    <img
      v-for="(item, i) in showImgs"
      :key="i"
      :src="item.goods_img"
      :style="{
        'z-index': 5 - i,
        [GB_cssRight ? 'margin-right' : 'margin-left']: `-${getLeftMargin(i)/37.5}rem`,
        'width': `${getWidth(i)/37.5}rem`,
        'height': `${getWidth(i)/37.5}rem`,
      }"
    />
    <div 
      v-if="total > 10"
      class="badge"
    >
      {{ badgeNum }}
    </div>
  </div>
</template>

<script>
const { GB_cssRight } = gbCommonInfo
const getRate = (i) => (10 - 1.6 * i) / 10

export default {
  name: 'GiftImgs',
  props: {
    list: {
      type: Array,
      default: () => ([]),
    },
    total: {
      type: [Number, String],
      default: 0,
    }
  },
  data() {
    return {
      GB_cssRight
    }
  },
  computed: {
    showImgs() {
      return this.list?.slice(0, 3)
    },
    badgeNum() {
      if (this.total >= 100) return '99+'
      return Math.floor(this.total / 10) * 10 + '+'
    }
  },
  methods: {
    getWidth(i) {
      return 36 * getRate(i)
    },
    getLeftMargin(i) {
      if (i == 0) return 0
      const w = this.getWidth(i)
      return w - 8 * getRate(i)
    },
  },
}
</script>

<style lang="less" scoped>
.gift-imgs {
  display: flex;
  align-items: center;
  min-width: 54/37.5rem;
  position: relative;
  img {
    width: 36/37.5rem;
    height: 36/37.5rem;
    border-radius: 6px;
    border: 0.5px solid #FFEDDF;
    box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.16);
    float: left;
    position: relative;
  }
  .badge {
    position: absolute;
    left: 20/37.5rem;
    top: 0;
    z-index: 10;
    padding: 0 4px;
    border: 1px solid #FFF;
    border-radius: 8px;
    color: #fff;
    background-color: #FE3B30;
    .font-dpr(20px)
  }
}
</style>
