<template>
  <span 
    class="circle-icon"
    :class="{
      'is-opacity': opacity,
    }"
    :style="{
      width: sizeFormat,
      height: sizeFormat,
    }"
  >
    <svg
      v-if="complete"
      xmlns="http://www.w3.org/2000/svg" 
      width="100%"
      height="100%"
      viewBox="0 0 10 10"
      fill="none"
    >
      <circle 
        cx="5" 
        cy="5" 
        r="5" 
        fill="white"
      />
      <path 
        d="M7.84539 2.40137C6.02012 3.52491 4.69591 4.94236 4.10073 5.65383L2.64355 4.50759L2 5.02836L4.51812 7.59934C4.95151 6.48515 6.32393 4.30661 8.00008 2.75911L7.84539 2.40137Z" 
        fill="#F83128"
      />
    </svg>
  </span>
</template>

<script>
export default {
  props: {
    size: {
      type: [String, Number],
      default: '10',
    },
    opacity: {
      type: Boolean,
      default: false,
    },
    complete: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    sizeFormat() {
      return `${this.size / 37.5}rem`
    }
  }
}
</script>

<style lang="less" scoped>
.circle-icon {
  background: #fff;
  border-radius: 50%;
  display: inline-flex;
  &.is-opacity {
    opacity: 0.8;
  }
}
</style>
