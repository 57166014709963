<template>
  <div class="right-widget">
  </div>
</template>

<script>
const { PUBLIC_CDN = '' } = gbCommonInfo || {}
export default {
  name: 'XMoneyNItem',
  computed: {
    headerCartBg() {
      return `url('${PUBLIC_CDN}/pwa_dist/images/add-on/promo-shop-cart-4af0fd3c9a.png')`
    },
  }
}
</script>

<style lang="less" scoped>
.right-widget {
  position: relative;
  height: 100%;
  width: 302/75rem;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: v-bind(headerCartBg);
    background-repeat: no-repeat;
    background-size: cover;
    transform: rotateY(0deg) /* rtl:rotateY(180deg) */;
  }
}
</style>
