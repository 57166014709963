<template>
  <div class="coupon-more">
    <div class="coupon-text">
      <p 
        class="discount-value"
        :style="fontStyle"
      >
        {{ title }}
      </p>
    </div>
    <div class="bottom-tips">
      {{ desc }}
    </div>
  </div>
</template>

<script>

export default {
  name: 'CouponMoreNewUser',
  props: {
    title: { // 券面额
      type: String,
      default: '',
    },
    desc: { // 券门槛
      type: String,
      default: '',
    },
    fontStyle: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>

<style lang="less" scoped>
.coupon-more {
  position: relative;
  padding: 38/37.5rem 46/37.5rem 25/37.5rem 27/37.5rem;
  width: 157/37.5rem;
  height: 100%;
  &::before {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-image: url('https://img.ltwebstatic.com/images3_ccc/2024/03/28/d8/1711615766e1e13809548b49a904a30bae84770ef6.png') /* rtl: url('https://img.ltwebstatic.com/images3_ccc/2024/03/28/b9/1711610934f36c1904c7fb378cb1ee27e5229cd049.png') */;
  }
  .coupon-text {
    position: relative;
    width: 100%;
    text-align: center;
    .discount-value {
      font-weight: bold;
      font-size: 18/37.5rem;
      line-height: 22/37.5rem;
      color: #5E2B0E;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  .bottom-tips {
    position: relative;
    margin-top: 4/37.5rem;
    width: 100%;
    font-size: 12/37.5rem;
    text-align: center;
    line-height: 14/37.5rem;
    color: #B2614A;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
</style>
